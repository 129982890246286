<template>
  <ion-header style="background-color: #FBE4D7;">
    <div class="centerCenter">
      <img src="../../../../public/assets/img/smallLogo.svg" width="32" />
    </div>

    <ion-toolbar color="transparent">
      <ion-segment :value="valueInicial" mode="md">
        <ion-segment-button value="inicia" v-on:click="goToLogin">
          Inicia Sesión
        </ion-segment-button>

        <ion-segment-button value="register" v-on:click="goToRegister">
          Regístrate
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ion-header>
</template>

<script >
import {
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../../router/index";

export default defineComponent({
  name: "LoginRegisterNav",
  components: {
    IonHeader,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
  },
  props: {
    valueInicial: String,
  },
  methods: {
    goToRegister() {
      router.replace("register");
    },
    goToLogin() {
      router.replace("login");
    },
  },
});
</script>

<style scoped>  

  ion-segment-button {
      font-family: "Avenir" !important;
      font-size: 16px !important;
      --color: rgb(212, 105, 39);
      --color-checked: #D46827;
      --indicator-height: 6px;
      letter-spacing: 1.5px;
      font-weight: 500 !important;
  }

  ion-segment-button::part(indicator-background) {
      background-color: #D46827;
  }

  .segment-button-checked {
    font-weight: bolder !important;
  }

  .centerCenter {
    margin-top: 10px;
  }

  .ios .centerCenter{
    margin: 50px 0 0;
  }
</style>
